/*@import "tailwindcss/base";*/

.builder-container{
  width: 100%;
}

@media (min-width: 640px){
  .builder-container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .builder-container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .builder-container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .builder-container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){
  .builder-container{
    max-width: 1536px;
  }
}

.builder-appearance-none{
  -webkit-appearance: none !important;
          appearance: none !important;
}

.builder-bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.builder-bg-primary{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.builder-border-transparent{
  border-color: transparent !important;
}

.builder-border-white{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.builder-border-gray-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.builder-border-gray-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.builder-border-gray-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.builder-border-gray-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.builder-border-blue-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.builder-border-blue-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.builder-border-primary{
  --tw-border-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.builder-rounded{
  border-radius: 0.25rem !important;
}

.builder-rounded-full{
  border-radius: 9999px !important;
}

.builder-rounded-t{
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.builder-rounded-b{
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.builder-border-2{
  border-width: 2px !important;
}

.builder-border{
  border-width: 1px !important;
}

.builder-border-t-0{
  border-top-width: 0px !important;
}

.builder-border-t{
  border-top-width: 1px !important;
}

.builder-border-r{
  border-right-width: 1px !important;
}

.builder-border-b{
  border-bottom-width: 1px !important;
}

.builder-border-l{
  border-left-width: 1px !important;
}

.builder-cursor-pointer{
  cursor: pointer !important;
}

.builder-cursor-move{
  cursor: move !important;
}

.builder-block{
  display: block !important;
}

.builder-inline-block{
  display: inline-block !important;
}

.builder-flex{
  display: flex !important;
}

.builder-flex-row{
  flex-direction: row !important;
}

.builder-flex-col{
  flex-direction: column !important;
}

.builder-flex-wrap{
  flex-wrap: wrap !important;
}

.builder-items-end{
  align-items: flex-end !important;
}

.builder-items-center{
  align-items: center !important;
}

.builder-self-start{
  align-self: flex-start !important;
}

.builder-justify-end{
  justify-content: flex-end !important;
}

.builder-justify-center{
  justify-content: center !important;
}

.builder-justify-between{
  justify-content: space-between !important;
}

.builder-flex-1{
  flex: 1 1 0% !important;
}

.builder-font-body{
  font-family: Helvetica Neue, sans-serif !important;
}

.builder-font-thin{
  font-weight: 100 !important;
}

.builder-font-light{
  font-weight: 300 !important;
}

.builder-font-medium{
  font-weight: 500 !important;
}

.builder-font-bold{
  font-weight: 700 !important;
}

.builder-h-4{
  height: 1rem !important;
}

.builder-h-6{
  height: 1.5rem !important;
}

.builder-h-8{
  height: 2rem !important;
}

.builder-h-16{
  height: 4rem !important;
}

.builder-h-20{
  height: 5rem !important;
}

.builder-h-24{
  height: 6rem !important;
}

.builder-h-64{
  height: 16rem !important;
}

.builder-h-full{
  height: 100% !important;
}

.builder-h-screen{
  height: 100vh !important;
}

.builder-text-xs{
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.builder-text-sm{
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.builder-text-lg{
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.builder-text-xl{
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.builder-text-2xl{
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.builder-text-3xl{
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.builder-text-5xl{
  font-size: 3rem !important;
  line-height: 1 !important;
}

.builder-leading-6{
  line-height: 1.5rem !important;
}

.builder-leading-none{
  line-height: 1 !important;
}

.builder-leading-tight{
  line-height: 1.25 !important;
}

.builder-leading-normal{
  line-height: 1.5 !important;
}

.builder-m-2{
  margin: 0.5rem !important;
}

.builder-m-4{
  margin: 1rem !important;
}

.builder-mx-4{
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.builder-mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}

.builder-mt-1{
  margin-top: 0.25rem !important;
}

.builder-mr-1{
  margin-right: 0.25rem !important;
}

.builder-mb-1{
  margin-bottom: 0.25rem !important;
}

.builder-ml-1{
  margin-left: 0.25rem !important;
}

.builder-mt-2{
  margin-top: 0.5rem !important;
}

.builder-mr-2{
  margin-right: 0.5rem !important;
}

.builder-mb-2{
  margin-bottom: 0.5rem !important;
}

.builder-ml-2{
  margin-left: 0.5rem !important;
}

.builder-mb-3{
  margin-bottom: 0.75rem !important;
}

.builder-mt-4{
  margin-top: 1rem !important;
}

.builder-mr-4{
  margin-right: 1rem !important;
}

.builder-mb-4{
  margin-bottom: 1rem !important;
}

.builder-ml-4{
  margin-left: 1rem !important;
}

.builder-mt-6{
  margin-top: 1.5rem !important;
}

.builder-mr-6{
  margin-right: 1.5rem !important;
}

.builder-mb-6{
  margin-bottom: 1.5rem !important;
}

.builder-mb-8{
  margin-bottom: 2rem !important;
}

.builder-mt-10{
  margin-top: 2.5rem !important;
}

.builder-max-h-full{
  max-height: 100% !important;
}

.builder-max-w-sm{
  max-width: 24rem !important;
}

.builder-max-w-md{
  max-width: 28rem !important;
}

.builder-max-w-lg{
  max-width: 32rem !important;
}

.focus\:builder-outline-none:focus{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.builder-overflow-hidden{
  overflow: hidden !important;
}

.builder-overflow-visible{
  overflow: visible !important;
}

.builder-overflow-scroll{
  overflow: scroll !important;
}

.builder-p-2{
  padding: 0.5rem !important;
}

.builder-p-3{
  padding: 0.75rem !important;
}

.builder-p-4{
  padding: 1rem !important;
}

.builder-p-6{
  padding: 1.5rem !important;
}

.builder-py-2{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.builder-px-2{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.builder-py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.builder-px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.builder-py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.builder-px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.builder-px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.builder-py-8{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.builder-px-8{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.builder-pb-0{
  padding-bottom: 0px !important;
}

.builder-pt-2{
  padding-top: 0.5rem !important;
}

.builder-pr-2{
  padding-right: 0.5rem !important;
}

.builder-pb-2{
  padding-bottom: 0.5rem !important;
}

.builder-pl-2{
  padding-left: 0.5rem !important;
}

.builder-pt-4{
  padding-top: 1rem !important;
}

.builder-pr-4{
  padding-right: 1rem !important;
}

.builder-pl-4{
  padding-left: 1rem !important;
}

.builder-fixed{
  position: fixed !important;
}

.builder-absolute{
  position: absolute !important;
}

.builder-relative{
  position: relative !important;
}

.builder-sticky{
  position: -webkit-sticky !important;
  position: sticky !important;
}

.builder-top-0{
  top: 0px !important;
}

.builder-right-0{
  right: 0px !important;
}

.builder-bottom-0{
  bottom: 0px !important;
}

.builder-left-0{
  left: 0px !important;
}

*{
  --tw-shadow: 0 0 #0000;
}

.builder-shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.builder-ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring-black{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.builder-ring-opacity-5{
  --tw-ring-opacity: 0.05 !important;
}

.builder-text-center{
  text-align: center !important;
}

.builder-text-white{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.builder-text-gray-200{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.builder-text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.builder-text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.builder-text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.builder-text-gray-700{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.builder-text-gray-800{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.builder-text-gray-900{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.builder-text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.builder-text-red-800{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.builder-text-blue-600{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.builder-text-blue-800{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.builder-text-primary{
  --tw-text-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.builder-italic{
  font-style: italic !important;
}

.builder-uppercase{
  text-transform: uppercase !important;
}

.builder-capitalize{
  text-transform: capitalize !important;
}

.hover\:builder-underline:hover{
  text-decoration: underline !important;
}

.builder-w-4{
  width: 1rem !important;
}

.builder-w-6{
  width: 1.5rem !important;
}

.builder-w-20{
  width: 5rem !important;
}

.builder-w-auto{
  width: auto !important;
}

.builder-w-1\/2{
  width: 50% !important;
}

.builder-w-1\/3{
  width: 33.333333% !important;
}

.builder-w-2\/3{
  width: 66.666667% !important;
}

.builder-w-1\/4{
  width: 25% !important;
}

.builder-w-1\/5{
  width: 20% !important;
}

.builder-w-4\/5{
  width: 80% !important;
}

.builder-w-full{
  width: 100% !important;
}

.builder-w-screen{
  width: 100vw !important;
}

@keyframes builder-spin{
  to{
    transform: rotate(360deg);
  }
}

@keyframes builder-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes builder-pulse{
  50%{
    opacity: .5;
  }
}

@keyframes builder-bounce{
  0%, 100%{
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@import "./editor.css";

.cards-drop-preview {
  background-color: rgba(214, 212, 212, 1);
  border: 1px dashed #abc;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 0px;
  padding-top: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring-black div {
  border-color: #000 transparent transparent transparent;
}

.lds-ring-white div {
  border-color: #fff transparent transparent transparent;
}

.react-time-picker>div, .react-datetime-picker>div {
  border-color: #f5f5f5 !important;
  color: rgba(74, 85, 104, 1) !important;
}

.picker-error .react-time-picker>div, .picker-error .react-datetime-picker>div {
  border-color: #fc8181 !important;
  color: #e53e3e !important;
}

.cls-1{
  fill:#d6b49a;
}

.cls-1,.cls-10,.cls-11,.cls-13,.cls-14,.cls-15,.cls-17,.cls-2,.cls-4,.cls-5,.cls-6{
  stroke:#000;
}

.cls-1,.cls-11,.cls-13,.cls-14,.cls-16,.cls-8{
  stroke-linecap:round;
  stroke-linejoin:round;
}

.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{
  stroke-width:3px;
}

.cls-2{
  fill:#020202;
}

.cls-10,.cls-12,.cls-15,.cls-17,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-9{
  stroke-miterlimit:10;
}

.cls-3{
  fill:#818181;
}

.cls-12,.cls-16,.cls-3,.cls-7,.cls-8,.cls-9{
  stroke:#191818;
}

.cls-4{
  fill:#dcdbda;
}

.cls-5{
  fill:#4ea7f1;
}

.cls-14,.cls-6{
  fill:#f8f3ed;
}

.cls-16,.cls-7{
  fill:#333;
}

.cls-13,.cls-8{
  fill:none;
}

.cls-9{
  fill:#f8f59c;
}

.cls-10,.cls-11{
  fill:#f3d2c9;
}

.cls-15{
  fill:#8bb174;
}

.cls-17{
  fill:#da4e22;
}

/*Picker V2*/

@media (min-width: 768px){

  .md\:builder-flex-row{
    flex-direction: row !important;
  }

  .md\:builder-text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .md\:builder-text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .md\:builder-w-1\/3{
    width: 33.333333% !important;
  }
}
