
.htmlContent ul {
    list-style: disc;
    margin-left: 20px;
}

.htmlContent strong {
    font-weight: 800;
}

.htmlContent a {
    text-decoration: underline;
}

.htmlContent h1, h2, h3 {
    margin-bottom: 10px;
}

.htmlContent p {
    min-height: 15px;
    margin-bottom: 5px;
}
