.rc-slider-handle, .rc-slider-handle-1, .rc-slider-handle-2 {
    width: 20px;
    height: 20px;
    margin-top: -8px;
    display: flex;
}

.rc-slider-handle:before, .rc-slider-handle-1:before, .rc-slider-handle-2:before {
    content: "\f104";
    /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: "Font Awesome 5 Pro";
    left: 2px;
    font-size: 10px;
    position: absolute;
    top: 1px;
}

.rc-slider-handle:after, .rc-slider-handle-1:after, .rc-slider-handle-2:after {
    content: "\f105";
    /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: "Font Awesome 5 Pro";
    left: 10px;
    font-size: 10px;
    position: absolute;
    top: 1px;
}


